import { atom } from 'recoil'

// eslint-disable-next-line import/prefer-default-export
export const hotjarEventToProcess = atom<string | null>({
  key: 'HotjarEvent',
  default: null,
})

export const bannerClosed = atom<boolean>({
  key: 'BannerClosed',
  default: false,
})
