export const logGoogleAdsConversion = (conversionId: string) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Would log GoogleAds conversion', conversionId)
  }

  if (typeof (window as any).gtag === 'function') {
    ;(window as any).gtag('event', 'conversion', { send_to: conversionId })
  }
}

export const logLinkedInInsightsConversion = (conversionId: number) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Would log LinkedIn Insights conversion', conversionId)
  }

  if (typeof (window as any).lintrk === 'function') {
    ;(window as any).lintrk('track', { conversion_id: conversionId })
  }
}

export const triggerHotJarEvent = (eventId: string) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Would trigger HotJar event conversion', eventId)
  }

  if ((typeof (window as any).hj as any) === 'function') {
    ;(window as any).hj('event', eventId)
  }
}

export const logGoogleAnalyticEvent = (eventName: string, eventParams = {}) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Would log GoogleAnalytics event conversion', eventName, eventParams)
  }

  if (typeof (window as any).gtag === 'function') {
    ;(window as any).gtag('event', eventName, eventParams)
  }
}

export const logGoogleTagManagerEvent = (eventName: string, eventParams = {}) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Would log GoogleTagManager event conversion', eventName, eventParams)
  }

  if (typeof (window as any).dataLayer === 'object') {
    ;(window as any).dataLayer.push({ event: eventName, ...eventParams })
  }
}

export const logFacebookEvent = (eventName: string, eventParams = {}) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Would log Facebook event conversion', eventName, eventParams)
  }

  if (typeof (window as any).fbq === 'function') {
    ;(window as any).fbq('trackCustom', eventName, eventParams)
  }
}

export const openCookieSettings = () => {
  if (typeof (window as any).cookiefirst_show_settings === 'function') {
    ;(window as any).cookiefirst_show_settings()
  }
}
