import 'styles/globals.css'
import 'components/ui/DateRangePicker/style.css'
import 'components/ui/ImageSlider/style.css'
import 'components/ui/Tooltip/style.css'
import 'components/ui/Testimonials/style.css'
import Script from 'next/script'

import type { AppProps } from 'next/app'
import axios from 'axios'
import { ApolloProvider } from '@apollo/client'
import { graphcmsProviderClient } from 'utils/graphcms'
import { RecoilRoot, useRecoilValue, useResetRecoilState } from 'recoil'
import { appWithTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { triggerHotJarEvent } from 'utils/tracking'
import Head from 'next/head'
import { hotjarEventToProcess } from '../store/tracking'
import { BASE_URL } from '../utils/constants'

axios.defaults.withCredentials = true
// @ts-ignore
axios.defaults.withXSRFToken = true

const gtmId = process.env.NEXT_PUBLIC_GTM_ID

const HotjarWatcher = () => {
  const hotjarEvent = useRecoilValue(hotjarEventToProcess)
  const resetHotjarEvent = useResetRecoilState(hotjarEventToProcess)

  useEffect(() => {
    if (hotjarEvent) {
      // TODO: Trigger this exactly when the route transition completes
      setTimeout(() => triggerHotJarEvent(hotjarEvent), 1000)
      resetHotjarEvent()
    }
  }, [hotjarEvent, resetHotjarEvent])

  return null
}

const MyApp = ({ Component, pageProps }: AppProps) => (
  <ApolloProvider client={graphcmsProviderClient}>
    <Head>
      <meta
        property="og:image"
        key="ogImage"
        content={`${BASE_URL}/images/opengraph/cloopio_1200x630.png`}
      />
    </Head>
    <RecoilRoot>
      <Component {...pageProps} />
      <HotjarWatcher />
    </RecoilRoot>

    {gtmId && (
      <Script id="google-tag-manager">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmId}');
        `}
      </Script>
    )}
  </ApolloProvider>
)

export default appWithTranslation(MyApp)
