import { ApolloClient, InMemoryCache } from '@apollo/client'
import { GRAPHCMS_PRIVATE_TOKEN, GRAPHCMS_PUBLIC_TOKEN, GRAPHCMS_URL } from 'utils/constants'

// Use this client on the server for direct queries
export const graphcms = new ApolloClient({
  uri: GRAPHCMS_URL,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${GRAPHCMS_PRIVATE_TOKEN}`,
  },
})

// Use this client for <ApolloProvider> to allow use of hooks on the client
export const graphcmsProviderClient = new ApolloClient({
  uri: GRAPHCMS_URL,
  cache: new InMemoryCache(),
  headers: GRAPHCMS_PUBLIC_TOKEN
    ? {
        Authorization: `Bearer ${GRAPHCMS_PUBLIC_TOKEN}`,
      }
    : {},
})
